import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

const ConditionScore = ({ car, carfaxVins }) => {
    const [score, setScore] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const hasValidVin = useMemo(() => {
        return car.vin && carfaxVins.has(car.vin.trim().toUpperCase());
    }, [car.vin, carfaxVins]);

    useEffect(() => {
        let isMounted = true;

        const fetchScore = async () => {
            if (!hasValidVin || isLoading) {
                return;
            }

            setIsLoading(true);
            try {
                const response = await fetch(`/api/cars/get-carfax-analysis/${car.id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                    }
                });

                if (!isMounted) return;

                if (response.ok) {
                    const data = await response.json();
                    setScore(data.condition_rating);
                    setError(null);
                } else {
                    throw new Error(`Error: ${response.status}`);
                }
            } catch (err) {
                if (isMounted) {
                    console.error('Error fetching condition score:', err);
                    setError('Failed to load score');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchScore();

        return () => {
            isMounted = false;
        };
    }, [car.id, hasValidVin]);

    // Assign CSS classes based on score ranges
    const getScoreClass = () => {
        if (score === null) return 'gray';
        if (score >= 75) return 'dark-green';
        if (score >= 50) return 'light-green';
        if (score >= 25) return 'orange';
        return 'red';
    };

    return (
        <Link to={`/carfax-analysis/${car.id}`}>
            <div className={`condition-score ${error ? 'red' : getScoreClass()}`}>
                {error ? 'Error' : score !== null ? Math.round(score) : 'Loading...'}
            </div>
        </Link>
    );
};

export default React.memo(ConditionScore);
