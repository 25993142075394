import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Login from "./components/Login";
import Signup from "./components/Signup";
import CarfaxAnalysis from "./components/CarfaxAnalysis";
import Contact from "./components/Contact";
import PrivacyPolicy from './components/PrivacyPolicy';
import AuthenticatedRequest from "./components/Authenticate";
import { AuthProvider, withRequiredAuthInfo } from "@propelauth/react";

// Wrap protected components with withRequiredAuthInfo
const ProtectedDashboard = withRequiredAuthInfo(Dashboard);
const ProtectedProfile = withRequiredAuthInfo(Profile);
const ProtectedCarfaxAnalysis = withRequiredAuthInfo(CarfaxAnalysis);

function App() {
  // Get auth URL from environment with fallback
  const authUrl = process.env.REACT_APP_AUTH_URL || 'https://auth.carclump.com';

  return (
    <AuthProvider
      authUrl={authUrl}
      onLoginCompleted={(authInfo) => {
        console.log("PropelAuth login completed:", {
          isLoggedIn: authInfo.isLoggedIn,
          hasToken: !!authInfo.accessToken,
          tokenLength: authInfo.accessToken?.length,
          userId: authInfo.user?.userId
        });

        // Store login status in sessionStorage for debugging
        sessionStorage.setItem('propelAuthLoginStatus', JSON.stringify({
          isLoggedIn: authInfo.isLoggedIn,
          hasToken: !!authInfo.accessToken,
          userId: authInfo.user?.userId,
          timestamp: new Date().toISOString()
        }));
      }}
      onLogoutCompleted={() => {
        console.log("PropelAuth logout completed");
        // Clear any stored tokens
        localStorage.removeItem('userTokens');
        sessionStorage.removeItem('propelAuthLoginStatus');
      }}
      redirectUriHost={window.location.origin}
    >
      <Router>
        <NavigationBar />
        <AuthenticatedRequest /> {/* Keeps the authentication token up-to-date */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<ProtectedDashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<ProtectedProfile />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/carfax-analysis/:carId" element={<ProtectedCarfaxAnalysis />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;