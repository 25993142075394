import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchCars, deleteCar } from '../api';
import './Dashboard.css';
import CarfaxAnalysis from './CarfaxAnalysis';
import ConditionScore from './ConditionScore';

const DebugVinRow = ({ car, carfaxVins }) => {
  const normalizedVin = car.vin?.trim().toUpperCase();

  return (
    <td className="debug-vin-cell">
      {normalizedVin && carfaxVins.has(normalizedVin) ? (
        <Link to={`/carfax-analysis/${car.id}`} className="view-analysis-button">
          View
        </Link>
      ) : (
        <div className="no-report">
          <div className="no-report-text">{car.vin ? 'No Report' : 'No VIN'}</div>
        </div>
      )}
    </td>
  );
};

function Dashboard() {
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]);
  const [sortConfig, setSortConfig] = useState(() => {
    const savedSortConfig = localStorage.getItem('sortConfig');
    return savedSortConfig ? JSON.parse(savedSortConfig) : { key: '', direction: 'ascending' };
  });
  const [filters, setFilters] = useState({
    make: '',
    model: '',
    year: '',
    mileageRange: { min: 0, max: 200000 },
  });
  const [carfaxVins, setCarfaxVins] = useState(new Set());

  const [authToken, setAuthToken] = useState(localStorage.getItem('jwtToken'));

  const getSiteFromUrl = (url) => {
    try {
      const hostname = new URL(url).hostname;
      const domain = hostname.replace('www.', '');

      const siteMap = {
        'cars.com': 'Cars.com',
        'carfax.com': 'Carfax.com',
        'carmax.com': 'Carmax.com',
        'craigslist.org': 'Craigslist',
        'autotrader.com': 'AutoTrader',
        'cargurus.com': 'CarGurus',
        'edmunds.com': 'Edmunds',
        'ksl.com': 'KSL Cars',
        'facebook.com': 'Facebook Marketplace',
      };

      for (const [key, value] of Object.entries(siteMap)) {
        if (domain.includes(key)) return value;
      }
      return domain;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return 'N/A';
    }
  };

  const loadCars = async () => {
    try {
      const carsData = await fetchCars(authToken);
      setCars(carsData);
      setFilteredCars(carsData);
    } catch (error) {
      console.error('Failed to fetch cars:', error);
      setCars([]);
    }
  };

  const fetchCarfaxVins = async () => {
    try {
      const response = await fetch('/api/cars/carfax-vins', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch Carfax VINs: ${response.status}`);
      }

      const data = await response.json();
      setCarfaxVins(new Set(data.vins));
    } catch (error) {
      console.error('Failed to fetch Carfax VINs:', error);
    }
  };

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        setAuthToken(token);
      }
    };

    // Check immediately
    checkToken();

    // Set up an interval to check periodically
    const interval = setInterval(checkToken, 1000);

    // Clean up
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const track = document.querySelector('.slider-track');
    if (track) {
      const min = filters.mileageRange.min;
      const max = filters.mileageRange.max;
      const range = 200000;

      track.style.setProperty('--left-percent', `${(min / range) * 100}%`);
      track.style.setProperty('--right-percent', `${100 - (max / range) * 100}%`);
    }
  }, [filters.mileageRange]);

  useEffect(() => {
    if (!authToken) {
      console.log('Waiting for auth token...');
      return;
    }

    const loadData = async () => {
      try {
        console.log('Loading data with auth token...');
        await loadCars();
        await fetchCarfaxVins();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, [authToken]);

  const handleDeleteCar = async (carId) => {
    try {
      if (!authToken) {
        console.error('No auth token available');
        return;
      }

      await deleteCar(carId, authToken);

      // Update both cars and filteredCars
      setCars(prevCars => prevCars.filter(car => car.id !== carId));
      setFilteredCars(prevFiltered => prevFiltered.filter(car => car.id !== carId));

    } catch (error) {
      console.error('Failed to delete car:', error);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedCars = [...filteredCars].sort((a, b) => {
      if (!a[key] && !b[key]) return 0;
      if (!a[key]) return direction === 'ascending' ? 1 : -1;
      if (!b[key]) return direction === 'ascending' ? -1 : 1;

      switch (key) {
        case 'price':
        case 'mileage':
        case 'year':
          return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
        case 'created_at':
          return direction === 'ascending'
            ? new Date(a[key]) - new Date(b[key])
            : new Date(b[key]) - new Date(a[key]);
        case 'url':
          return direction === 'ascending'
            ? getSiteFromUrl(a[key]).localeCompare(getSiteFromUrl(b[key]))
            : getSiteFromUrl(b[key]).localeCompare(getSiteFromUrl(a[key]));
        case 'condition_rating':
          // Get condition rating directly from the car object
          const ratingA = a.condition_rating ?? 0;
          const ratingB = b.condition_rating ?? 0;
          return direction === 'ascending' ? ratingA - ratingB : ratingB - ratingA;
        default:
          return direction === 'ascending'
            ? String(a[key] || '').localeCompare(String(b[key] || ''))
            : String(b[key] || '').localeCompare(String(a[key] || ''));
      }
    });

    setSortConfig({ key, direction });
    localStorage.setItem('sortConfig', JSON.stringify({ key, direction }));
    setFilteredCars(sortedCars);
  };

  const calculateDaysAgo = (dateString) => {
    const createdDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate - createdDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysAgo === 0 ? 'Today' : `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  };

  const applyFilters = () => {
    const filtered = cars.filter(
      (car) =>
        (filters.make ? car.make === filters.make : true) &&
        (filters.model ? car.model === filters.model : true) &&
        (filters.year ? car.year === Number(filters.year) : true) &&
        (!car.mileage ||
          (car.mileage >= filters.mileageRange.min && car.mileage <= filters.mileageRange.max))
    );
    setFilteredCars(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [filters, cars]);

  const handleFilterChange = (event, key) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: event.target.value }));
  };

  const handleMileageChange = (type, value) => {
    setFilters((prev) => {
      const newRange = { ...prev.mileageRange };

      if (type === 'min') {
        newRange.min = Math.min(value, prev.mileageRange.max - 1000);
      } else {
        newRange.max = Math.max(value, prev.mileageRange.min + 1000);
      }

      return { ...prev, mileageRange: newRange };
    });
  };

  const getUniqueValues = (key) => {
    return key === 'year'
      ? [...new Set(cars.map((car) => car[key]))]
        .filter(Boolean)
        .map(Number)
        .sort((a, b) => b - a)
      : [...new Set(cars.map((car) => car[key]))].filter(Boolean);
  };

  return (
    <div className="dashboard-container">
      <h2>Your Saved Cars</h2>
      <div className="filters">
        <label>
          Make:
          <select value={filters.make} onChange={(e) => handleFilterChange(e, 'make')}>
            <option value="">All</option>
            {getUniqueValues('make').map((make) => (
              <option key={make} value={make}>
                {make}
              </option>
            ))}
          </select>
        </label>

        <label>
          Model:
          <select value={filters.model} onChange={(e) => handleFilterChange(e, 'model')}>
            <option value="">All</option>
            {getUniqueValues('model').map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        </label>

        <label>
          Year:
          <select value={filters.year} onChange={(e) => handleFilterChange(e, 'year')}>
            <option value="">All</option>
            {getUniqueValues('year').map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </label>

        <div className="mileage-filter">
          <label>Mileage Range:</label>
          <div className="mileage-inputs">
            <div className="range-slider-container">
              <input
                type="range"
                min="0"
                max="200000"
                value={filters.mileageRange.min}
                onChange={(e) => handleMileageChange('min', parseInt(e.target.value))}
                className="mileage-slider min-slider"
              />
              <input
                type="range"
                min="0"
                max="200000"
                value={filters.mileageRange.max}
                onChange={(e) => handleMileageChange('max', parseInt(e.target.value))}
                className="mileage-slider max-slider"
              />
              <div className="slider-track"></div>
            </div>
            <div className="mileage-values">
              <span>{filters.mileageRange.min.toLocaleString()} miles</span>
              <span>{filters.mileageRange.max.toLocaleString()} miles</span>
            </div>
          </div>
        </div>
      </div>

      {filteredCars.length > 0 ? (
        <div className="dashboard-table-container">
          <table className="car-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('url')}>Car</th>
                <th onClick={() => handleSort('make')}>Make</th>
                <th onClick={() => handleSort('model')}>Model</th>
                <th onClick={() => handleSort('year')}>Year</th>
                <th onClick={() => handleSort('price')}>Price</th>
                <th onClick={() => handleSort('mileage')}>Mileage</th>
                <th onClick={() => handleSort('exterior_color')}>Exterior</th>
                <th onClick={() => handleSort('vin')}>VIN</th>
                <th onClick={() => handleSort('created_at')}>Date Added</th>
                <th onClick={() => handleSort('condition_rating')} style={{ cursor: 'pointer' }}>Condition Rating</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCars.map((car, index) => (
                <tr key={index}>
                  <td>{car.url ? <a href={car.url} target="_blank" rel="noopener noreferrer">{getSiteFromUrl(car.url)}</a> : 'N/A'}</td>
                  <td>{car.make}</td>
                  <td>{car.model}</td>
                  <td>{car.year}</td>
                  <td>${car.price?.toLocaleString() ?? 'N/A'}</td>
                  <td>{car.mileage ? car.mileage.toLocaleString() : 'N/A'}</td>
                  <td>{car.exterior_color || 'N/A'}</td>
                  <td>{car.vin || 'No VIN found'}</td>
                  <td>{calculateDaysAgo(car.created_at)}</td>
                  <td className="condition-rating">
                    <ConditionScore car={car} carfaxVins={carfaxVins} />
                  </td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteCar(car.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No cars available</p>
      )}
    </div>
  );
}

export default Dashboard;