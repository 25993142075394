import { useEffect, useRef } from "react";
import { useAuthInfo } from "@propelauth/react";

// API base URL for making authenticated requests
const API_BASE_URL = process.env.REACT_APP_PROD_SERVER_URL;

// Helper functions for managing user tokens
const UserTokenManager = {
  // Store token for a specific user
  setToken: (userId, token) => {
    const userTokens = UserTokenManager._getTokensFromStorage();
    userTokens[userId] = {
      token,
      timestamp: Date.now()
    };
    localStorage.setItem('userTokens', JSON.stringify(userTokens));
  },

  // Get token for a specific user
  getToken: (userId) => {
    const userTokens = UserTokenManager._getTokensFromStorage();
    return userTokens[userId]?.token || null;
  },

  // Get all stored tokens
  getAllTokens: () => {
    return UserTokenManager._getTokensFromStorage();
  },

  // Private method to get tokens from storage
  _getTokensFromStorage: () => {
    try {
      return JSON.parse(localStorage.getItem('userTokens') || '{}');
    } catch (e) {
      console.error('Error parsing stored tokens:', e);
      return {};
    }
  },

  // Remove token for a specific user
  removeToken: (userId) => {
    const userTokens = UserTokenManager._getTokensFromStorage();
    delete userTokens[userId];
    localStorage.setItem('userTokens', JSON.stringify(userTokens));
  },

  // Clear all stored tokens
  clearAllTokens: () => {
    localStorage.removeItem('userTokens');
  },

  // Check if token is expired
  isTokenExpired: (userId) => {
    const userTokens = UserTokenManager._getTokensFromStorage();
    const tokenData = userTokens[userId];
    if (!tokenData) return true;

    const TOKEN_EXPIRY_TIME = 3600000; // 1 hour
    return Date.now() - tokenData.timestamp > TOKEN_EXPIRY_TIME;
  },

  // Cleanup expired tokens
  cleanupExpiredTokens: () => {
    const userTokens = UserTokenManager._getTokensFromStorage();
    const TOKEN_EXPIRY_TIME = 3600000; // 1 hour
    const now = Date.now();

    let hasChanges = false;
    Object.keys(userTokens).forEach(userId => {
      if (now - userTokens[userId].timestamp > TOKEN_EXPIRY_TIME) {
        delete userTokens[userId];
        hasChanges = true;
      }
    });

    if (hasChanges) {
      localStorage.setItem('userTokens', JSON.stringify(userTokens));
    }
  },

  // Get last activity for user
  getLastActivityForUser: async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/last-activity/${userId}`, {
        credentials: 'include',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      // If the response is not OK but not 404, throw an error
      if (!response.ok && response.status !== 404) {
        throw new Error(`HTTP error ${response.status}`);
      }

      // For both 200 OK responses and 404 Not Found, try to parse the JSON
      const data = await response.json().catch(e => ({ lastActivity: 0 }));

      // Return 0 if no lastActivity value found
      return data.lastActivity || 0;
    } catch (error) {
      console.warn(`Error fetching activity for user ${userId}:`, error);
      // Return 0 as a safe default that won't trigger immediate cleanup
      return 0;
    }
  },

  // Cleanup inactive sessions
  cleanupInactiveSessions: async () => {
    try {
      const userTokens = UserTokenManager._getTokensFromStorage();
      const INACTIVE_TIMEOUT = 7200000; // 2 hours
      const now = Date.now();

      let hasChanges = false;
      for (const userId in userTokens) {
        try {
          const lastActivity = await UserTokenManager.getLastActivityForUser(userId);

          // Handle the case where lastActivity is 0 (no session found)
          if (lastActivity === 0 || now - lastActivity > INACTIVE_TIMEOUT) {
            console.log(`Removing inactive token for user: ${userId}`);
            delete userTokens[userId];
            hasChanges = true;
          }
        } catch (error) {
          // Don't remove the token just because we failed to check activity
          console.error(`Error checking activity for user ${userId}:`, error);
          // Only remove token if it's an auth error (401)
          if (error.message && error.message.includes('401')) {
            delete userTokens[userId];
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        localStorage.setItem('userTokens', JSON.stringify(userTokens));
      }
    } catch (error) {
      console.error("Error during session cleanup:", error);
    }
  }
};

// Function to send token to extension with retry logic
// Function to send token to extension with retry logic
function sendTokenToExtension(token, userId) {
  const environment = process.env.NODE_ENV;
  const message = {
    type: "FROM_PAGE_TO_EXTENSION",
    token: token,
    userId: userId,
    environment: environment,
  };

  let attempts = 0;
  const maxAttempts = 3;
  const RETRY_DELAY = 1000;

  const sendMessage = () => {
    if (!token) {
      console.error("Cannot send empty token to extension");
      return Promise.reject(new Error("Empty token"));
    }

    window.postMessage(message, "*");
    console.log(`Attempt ${attempts + 1}: Sending token to extension for user ${userId} (${environment})`);
  };

  return new Promise((resolve, reject) => {
    // Track whether we've already resolved this promise
    let isResolved = false;

    const verifyReceipt = (event) => {
      // Only process messages of the expected type to avoid triggering on unrelated messages
      if (event.data && event.data.type === "TOKEN_RECEIVED_BY_EXTENSION") {
        console.log("Token receipt confirmed by extension");
        window.removeEventListener("message", verifyReceipt);
        if (!isResolved) {
          isResolved = true;
          resolve();
        }
      }
    };

    // Set up a retry mechanism separate from the message listener
    const attemptRetry = () => {
      if (isResolved) return;

      if (attempts < maxAttempts) {
        attempts++;
        console.log(`Retrying token send (${attempts}/${maxAttempts})`);
        sendMessage();
        setTimeout(attemptRetry, RETRY_DELAY);
      } else if (!isResolved) {
        console.error("Failed to confirm token receipt by extension");
        window.removeEventListener("message", verifyReceipt);
        isResolved = true;
        reject(new Error("Token confirmation failed"));
      }
    };

    window.addEventListener("message", verifyReceipt);

    // Initial send
    sendMessage();

    // Start retry timer
    setTimeout(attemptRetry, RETRY_DELAY);

    // Cleanup after timeout
    setTimeout(() => {
      if (!isResolved) {
        window.removeEventListener("message", verifyReceipt);
        isResolved = true;
        reject(new Error("Token confirmation timeout"));
      }
    }, (maxAttempts + 2) * RETRY_DELAY);
  });
}

// Function to handle authentication and store the token locally
async function authenticate(accessToken, userId) {
  console.log("Authenticating user:", userId);

  if (!accessToken) {
    console.error("Access token is null, authentication cannot proceed.");
    return Promise.reject(new Error("Access token is null"));
  }

  if (!userId) {
    console.error("User ID is required for authentication");
    return Promise.reject(new Error("User ID is required"));
  }

  // Add token format validation with detailed logging
  if (typeof accessToken !== 'string' || !accessToken.trim()) {
    console.error("Invalid access token format:", {
      type: typeof accessToken,
      isEmpty: !accessToken.trim(),
      length: accessToken.length
    });
    return Promise.reject(new Error("Invalid access token format"));
  }

  try {
    console.log(`Sending authentication request to ${API_BASE_URL}/auth/api/authenticate`);

    // Create proper headers and validate token format
    const tokenToSend = accessToken.startsWith('Bearer ')
      ? accessToken.replace('Bearer ', '')
      : accessToken;

    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${tokenToSend}`
    };

    console.log("Authentication headers:", {
      contentType: headers["Content-Type"],
      authHeader: `Bearer ${tokenToSend.substring(0, 10)}...`,
      tokenLength: tokenToSend.length
    });

    const response = await fetch(`${API_BASE_URL}/auth/api/authenticate`, {
      method: "POST",
      headers,
      credentials: "include",
    });

    console.log("Authentication response status:", response.status);

    if (!response.ok) {
      let errorData = {};
      try {
        errorData = await response.json();
      } catch (e) {
        console.error("Failed to parse error response:", e);
      }

      const errorMessage = errorData.error || `Authentication failed with status: ${response.status}`;

      // Log detailed error information
      console.error("Authentication failed:", {
        status: response.status,
        statusText: response.statusText,
        error: errorMessage,
        responseData: errorData
      });

      throw new Error(errorMessage);
    }

    const data = await response.json();
    console.log("Authentication successful, data:", data);

    // Store token for this specific user
    UserTokenManager.setToken(userId, accessToken);
    console.log(`Token stored for user ${userId}`);

    // Send token to extension
    try {
      console.log("Sending token to extension...");
      await sendTokenToExtension(accessToken, userId);
      console.log("Token sent to extension successfully");
    } catch (extensionError) {
      console.warn("Failed to send token to extension:", extensionError.message);
      // Don't fail the whole authentication process if extension communication fails
    }

    return data;
  } catch (error) {
    console.error("Authentication error:", error.message);
    // Clear invalid token for this user
    UserTokenManager.removeToken(userId);
    throw error;
  }
}

async function testPropelAuthConnection() {
  try {
    console.log("Testing PropelAuth connection...");
    const response = await fetch(`${API_BASE_URL}/api/test-auth-connection`, {
      credentials: 'include'
    });

    const data = await response.json();
    console.log("PropelAuth connection test result:", data);
    return data;
  } catch (error) {
    console.error("PropelAuth connection test failed:", error);
    return { status: "error", error: error.message };
  }
}

window.addEventListener('DOMContentLoaded', () => {
  console.log("Checking PropelAuth configuration...");
  console.log("Auth URL configured as:", process.env.REACT_APP_AUTH_URL);
  console.log("API Base URL:", API_BASE_URL);

  // Test the connection (don't await, let it run in background)
  testPropelAuthConnection();
});

function AuthenticatedRequest() {
  const authInfo = useAuthInfo();
  const lastRunTimeRef = useRef({});
  const lastTokenRef = useRef({});
  const retryCountRef = useRef({});
  const MAX_RETRIES = 3;

  // Add detailed logging for PropelAuth state
  useEffect(() => {
    console.log("PropelAuth state:", {
      isLoggedIn: authInfo.isLoggedIn,
      hasAccessToken: !!authInfo.accessToken,
      hasUser: !!authInfo.user,
      userId: authInfo.user?.userId,
      tokenLength: authInfo.accessToken ? authInfo.accessToken.length : 0
    });

    // If logged in without proper credentials, investigate further
    if (authInfo.isLoggedIn && (!authInfo.accessToken || !authInfo.user)) {
      console.warn("Incomplete PropelAuth data - missing critical information:", {
        accessToken: authInfo.accessToken ? "Present" : "Missing",
        user: authInfo.user ? "Present" : "Missing"
      });
    }
  }, [authInfo]);

  useEffect(() => {
    let isMounted = true;
    const RETRY_DELAY = 1000;

    const handleAuthentication = async () => {
      // Enhanced logging with detailed information
      console.log("Authentication check:", {
        isLoggedIn: authInfo.isLoggedIn,
        hasAccessToken: !!authInfo.accessToken,
        hasUserId: !!authInfo.user?.userId,
        auth: typeof authInfo
      });

      if (!authInfo.isLoggedIn || !authInfo.accessToken || !authInfo.user?.userId) {
        console.log("User not logged in or missing credentials");
        // Clear any stale data for this user
        if (authInfo.user?.userId) {
          UserTokenManager.removeToken(authInfo.user.userId);
          retryCountRef.current[authInfo.user.userId] = 0;
        }
        return;
      }

      const userId = authInfo.user.userId;
      const logPrefix = `[User: ${userId.slice(0, 8)}]`; // Add user-specific logging prefix

      // Initialize retry count for this user if needed
      if (!retryCountRef.current[userId]) {
        retryCountRef.current[userId] = 0;
      }

      try {
        // Add concurrent authentication check with more detailed logging
        const currentToken = UserTokenManager.getToken(userId);
        console.log(`${logPrefix} Token check:`, {
          hasStoredToken: !!currentToken,
          isTokenExpired: currentToken ? UserTokenManager.isTokenExpired(userId) : true
        });

        if (currentToken && currentToken === authInfo.accessToken && !UserTokenManager.isTokenExpired(userId)) {
          console.log(`${logPrefix} Using existing valid token`);
          return;
        }

        console.log(`${logPrefix} Attempting authentication with token:`, {
          tokenStart: authInfo.accessToken.substring(0, 10) + '...',
          tokenLength: authInfo.accessToken.length
        });

        const response = await authenticate(authInfo.accessToken, userId);

        if (isMounted) {
          console.log(`${logPrefix} Authentication successful:`, response);
          lastRunTimeRef.current[userId] = Date.now();
          lastTokenRef.current[userId] = authInfo.accessToken;
          retryCountRef.current[userId] = 0;
        }
      } catch (error) {
        console.error(`${logPrefix} Authentication error:`, error.message);

        if (error.message.includes("500")) {
          if (retryCountRef.current[userId] < MAX_RETRIES) {
            retryCountRef.current[userId]++;
            console.log(`${logPrefix} Retrying authentication (${retryCountRef.current[userId]}/${MAX_RETRIES})...`);
            setTimeout(() => handleAuthentication(), RETRY_DELAY);
          } else {
            console.error(`${logPrefix} Max retries reached for user:`, userId);
            UserTokenManager.removeToken(userId);
            retryCountRef.current[userId] = 0;
            if (authInfo.refresh) {
              console.log(`${logPrefix} Attempting to refresh authentication...`);
              authInfo.refresh();
            }
          }
        } else {
          UserTokenManager.removeToken(userId);
          retryCountRef.current[userId] = 0;
        }
      }
    };

    handleAuthentication();

    // Add cleanup interval for expired tokens
    const cleanupInterval = setInterval(() => {
      UserTokenManager.cleanupExpiredTokens();
    }, 60000); // Check every minute

    // Add periodic session cleanup
    const sessionCleanupInterval = setInterval(() => {
      UserTokenManager.cleanupInactiveSessions();
    }, 300000); // Check every 5 minutes

    return () => {
      isMounted = false;
      clearInterval(cleanupInterval);
      clearInterval(sessionCleanupInterval);
    };
  }, [authInfo]);

  return null;
}

// Log the current active users
console.log("Active authenticated users:", Object.keys(UserTokenManager.getAllTokens()));

export default AuthenticatedRequest;